<template>
    <v-row>
        <v-col class="pb-0" cols="12">
            <v-btn text to="/usuario/cadastrar" v-if="permissao('usuario_cadastrar')"><v-icon left>mdi-plus</v-icon>Cadastrar usuário</v-btn>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2 hidden-xs-only">Filtrar</v-toolbar-title>
                    <v-spacer class="hidden-xs-only"/>
                    <v-row>
                        <v-col cols="12" md="6" offset-md="6"><v-text-field dense label="Buscar:" hide-details flat outlined v-model="filtro.busca" /></v-col>
                    </v-row>
                </v-toolbar>
                <UsuarioListar :filtro="filtro" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import UsuarioListar from "@/Views/Usuario/Component/UsuarioListar"
    import {mapState} from 'vuex'

    export default {
        name: "Usuario",
        components: {UsuarioListar},
        data() {
            return {
                filtro : {}
            }
        },
        computed : {
            ...mapState(['baseUrl'])
        },
        methods : {

        }
    }
</script>

<style scoped>

</style>
